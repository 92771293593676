<script lang="ts" context="module">
  /**
   * Module Imports
   * ---------------------------------------------------------------------------
   */

  import type { PodcastEntry } from "@customTypes/entries";
  import type { MediaItem } from "@customTypes/subsplash";

  /**
   * Module Types
   * ---------------------------------------------------------------------------
   */

  /**
   * A Podcast MediaItem together with its related PodcastEntry.
   */
  export interface PodcastMediaItem {
    item: MediaItem;
    podcastEntry: PodcastEntry;
  }
</script>

<script lang="ts">
  /**
   * Imports
   * ---------------------------------------------------------------------------
   */

  import CardSlider from "@components/content/CardSlider.svelte";
  import type { CardProps } from "@components/content/Card.svelte";
  import { formatDateUS } from "@utils/dateTime";
  import { embeddedSquareImageUrl } from "@utils/subsplashMedia";

  /**
   * Props
   * ---------------------------------------------------------------------------
   */

  export let podcastMediaItems: PodcastMediaItem[] = [];

  /**
   * Functions
   * ---------------------------------------------------------------------------
   */

  /**
   * Given a PodcastMediaItem, return CardProps.
   */
  function podcastMediaItemToCardProps(
    podcastMediaItem: PodcastMediaItem,
  ): CardProps {
    const { item, podcastEntry } = podcastMediaItem;
    return {
      title: item.title,
      leader: !!item.date ? formatDateUS(item.date) : undefined,
      tag: "Podcast",
      featuredImageSrc: embeddedSquareImageUrl(item) || "",
      featuredImageAspectRatio: "square",
      description: item.summary_text,
      href: `/podcasts/${podcastEntry.slug}/${item.slug}`,
    };
  }

  /**
   * Data
   * ---------------------------------------------------------------------------
   */
  const cards = podcastMediaItems.map(podcastMediaItemToCardProps);
</script>

<!-- Template ----------------------------------------------------------------->

<CardSlider {cards} />
